<template>
<div>
    <v-row>
        <v-col cols="12" sm="6" >
            <v-card class="rounded-lg" flat>
                <v-overlay absolute :opacity=".6" :color="TemaDark?'grey darken-4':'white'" :value="overlay" z-index="3">
                    <span class="black--text">Cargando...</span>
                    <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                </v-overlay>
                <v-card flat class="rounded-lg mr-2">
                    <v-toolbar flat class="rounded-t-lg" v-if="!skeleton" height="56">
                        <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                            <v-list-item class="pl-0 rounded-t-lg">
                                <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="headline pb-0 mb-0">
                                        <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">{{datos.nombre}} {{datos.apellido}}</v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium">
                                        <span class="text-capitalize">{{TurnoTipo}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn icon @click="OpenDialog('perfil_pass')">
                            <v-icon color="primary" size="20">mdi-lock-reset</v-icon>
                        </v-btn>
                        <v-btn icon @click="OpenDialog('perfil_edit')">
                            <v-icon color="primary" size="20">mdi-account-edit</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card>
                <v-container v-if="!skeleton">
                    <v-row class="pb-2">
                        <v-col class="pa-0" cols="12">
                            <v-list-item>
                                <v-list-item-avatar class="my-1 mr-1">
                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-home-outline</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                        {{Residencia}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-capitalize">Residencia</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :href="datos.telefono?`tel:${TelFormat}`:null">
                                <v-list-item-avatar class="my-1 mr-1">
                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-phone</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                        {{datos.telefono?TelFormat:'No Registrado'}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Contacto</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar class="my-1 mr-1">
                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-email-outline</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                        {{datos.correo}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Correo</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar class="my-1 mr-1">
                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-card-account-details-outline</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                        {{TarjetaFormat}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Vencimiento tarjeta</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card v-if="skeleton" class="rounded-lg" flat>
                    <v-skeleton-loader type="list-item-avatar-two-line" />
                    <v-skeleton-loader type="list-item-avatar-two-line" />
                    <v-skeleton-loader type="list-item-avatar-two-line" />
                </v-card>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6">
            <v-card class="rounded-lg" flat>
                <v-card flat class="rounded-lg mr-2">
                    <v-toolbar flat class="rounded-t-lg" v-if="!skeleton" height="56">
                        <v-list-item class="pl-0 rounded-t-lg">
                            <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0">
                                    <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Trenos</v-toolbar-title>
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-medium">
                                    <span class="text-capitalize">Informacion</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-spacer/>
                        <v-btn icon @click="LogOut">
                            <v-icon color="primary" size="20">mdi-exit-to-app</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card>
                <v-container class="pb-0">
                    <v-row>
                        <v-col cols="12 pt-0">
                            <v-list flat class="px-0 py-0 rounded-lg">
                                <v-list-item class="rounded-lg px-1">
                                    <v-list-item-avatar class="my-1 mr-1">
                                        <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                            <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-information-outline</v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{sistema.app_version}}</v-list-item-title>
                                        <v-list-item-subtitle>Version</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-group class="px-0 tema-opciones">
                                    <template v-slot:activator>
                                        <v-list-item-avatar class="my-1 mr-1">
                                            <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                                <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-theme-light-dark</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                                Tema
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <v-radio-group v-model="tema" class="py-0 my-0 pl-8">
                                        <v-container class="pl-2">
                                            <v-radio label="Sistema" value="system"></v-radio>
                                            <v-radio label="Claro" value="light"></v-radio>
                                            <v-radio label="Oscuro" value="dark"></v-radio>
                                        </v-container>
                                    </v-radio-group>
                                </v-list-group>
                                <v-list-item link class="rounded-lg px-1" @click="RefeshPage()">
                                    <v-list-item-avatar class="my-1 mr-1">
                                        <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                            <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-refresh</v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">Reiniciar App</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog.perfil_edit" persistent max-width="400" :transition="DialogAnimation" class="rounded-lg" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-card class="rounded-lg">
            <v-card-text class="px-2">
                <v-card-title class="pa-0 modal-title" v-if="!skeleton">
                    <v-toolbar flat height="62" class="rounded-t-lg">
                        <v-list-item class="px-0">
                            <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0">
                                    <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Editar Datos</v-toolbar-title>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-spacer></v-spacer>
                        <!--<v-btn icon @click="CerrarEdit()" class="mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>-->
                    </v-toolbar>
                </v-card-title>
                <PerfilEdit :datos="datos_edit" vista="perfil" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseDialog('perfil_edit')">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.perfil_pass" persistent max-width="400" :transition="DialogAnimation" class="rounded-lg" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-card class="rounded-lg">
            <v-card-text class="px-0">
                <v-card-title class="pa-0 modal-title" v-if="!skeleton">
                    <v-toolbar flat height="62" class="rounded-t-lg">
                        <v-list-item class="px-0">
                            <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0">
                                    <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Cambiar Contraseña</v-toolbar-title>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!--<v-btn icon @click="dialog_pass = false" class="mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>-->
                    </v-toolbar>
                </v-card-title>
                <v-container class="pb-0">
                    <v-row class="px-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field label="Ingresa tu nueva contraseña" outlined dense prepend-icon="mdi-lock-outline" v-model="nueva_pass" :maxlength="5" autocomplete="off" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center">
                            <v-btn depressed color="primary" @click="dialog_pass = NewPass()" :disabled="!EditPassBtn">Modificar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseDialog('perfil_pass')">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    mapState,
    mapMutations
} from 'vuex'
import dayjs from "dayjs";
export default {
    name: 'Perfil',
    components: {
        PerfilEdit: () => import('@/components/PerfilEdit')
    },
    data() {
        return {
            datos: {},
            overlay: false,
            skeleton: true,
            dialog_edit: false,
            dialog_pass: false,
            datos_edit: {},
            nueva_pass: null,
            tarjeta_dialog: false,
            tema: null
        }
    },
    computed: {
        ...mapState(['usuario', 'sistema', 'dialog', 'update']),
        TelFormat() { //Genera el formato del telefono (movil)
            let numero
            if (this.datos.telefono && this.datos.telefono.length == 8) {
                numero = `+569${this.datos.telefono}`
            }
            return numero
        },
        TarjetaFormat() { //Genera el formato para la fecha del vencimiento de la tarjeta
            let fecha_tarjeta = 'Sin Informacion'
            if (this.datos.tarjeta) {
                fecha_tarjeta = dayjs(this.datos.tarjeta).format('DD MMMM YYYY')
            }
            return fecha_tarjeta
        },
        DialogEdit() { //Entrega el estado del dialog_Edit
            return this.dialog.perfil_edit
        },
        UpdateComponente() { //Entrega el estado del perfil (update)
            return this.update.perfil
        },
        Residencia() { //Entrega el nombre de la comuna con base en el codigo de ésta
            let residencia = 'Sin informacion'
            if (this.datos.residencia) {
                switch (this.datos.residencia) {
                    case 11:
                        residencia = 'Valparaíso'
                        break;
                    case 12:
                        residencia = 'Viña del Mar'
                        break;
                    case 13:
                        residencia = 'Concon'
                        break;
                    case 14:
                        residencia = 'Casablanca'
                        break;
                    case 15:
                        residencia = 'Santiago'
                        break;
                    case 21:
                        residencia = 'Quilpué'
                        break;
                    case 22:
                        residencia = 'Villa Alemana'
                        break;
                    case 31:
                        residencia = 'Limache'
                        break;
                    case 32:
                        residencia = 'Olmué'
                        break;
                    case 33:
                        residencia = 'Quillota'
                        break;
                }

            }
            return residencia
        },
        TurnoTipo() { //Entrega el tipo de turno con base en la sigla del mismo.
            let turno_tipo = 'Sin informacion'
            if (this.datos.tipo) {
                switch (this.datos.tipo) {
                    case 'ft':
                        turno_tipo = 'Full Time'
                        break;
                    case 'pt':
                        turno_tipo = 'Part Time 80'
                        break;
                    case 'pts':
                        turno_tipo = 'Part Time 120'
                        break;
                    case 'il':
                        turno_tipo = 'Inspector de Línea'
                        break;
                    case 'adm':
                        turno_tipo = 'Administrador'
                        break;
                    case 'ctc':
                        turno_tipo = 'Controlador Trtáfico'
                        break;
                    case 'etl':
                        turno_tipo = 'Encargado Terminal'
                        break;

                }
            }
            return turno_tipo
        },
        EditPassBtn(){
            let disabled = false
            if(this.nueva_pass && this.nueva_pass.length == 5) disabled = true
            return disabled
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(['LogOut', 'ShowSnackBar', 'ErrorRequest', 'CloseDialog', 'OpenDialog']),
        async GetData() {
            this.overlay = true
            await this.axios.get(`/perfil/`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.skeleton = false
                    this.overlay = false
                    this.datos = Object.freeze(respuesta.data)
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        OpenDialogEdit() {
            this.OpenDialog('perfil_edit')
            this.dialog_edit = true
            this.datos_edit = JSON.parse(JSON.stringify(this.datos))
        },
        OpenDialogPass() {
            this.dialog_pass = true
            this.nueva_pass = null
        },
        async NewPass() {
            this.overlay = true
            await this.axios.post(`/perfil/newpass`, {
                newpass: this.nueva_pass
            }, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    this.dialog_pass = false
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        RefeshPage() {
            window.location.reload();
        },
        CerrarEdit() {
            this.dialog_edit = false
            this.CloseDialog('perfil_edit')
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.tema = this.sistema.tema
        this.GetData()
    },
    watch: {
        DialogEdit: function (val) {
            if (!val) this.CerrarEdit()
        },
        UpdateComponente: function (val) {
            if (val) {
                this.GetData()
                this.update.perfil = false
            }
        },
        tema: function (val) {
            if(val){
                let app_data = JSON.parse(localStorage.getItem("app_data"))
                switch (val) {
                    case 'light':
                        this.sistema.tema = 'light'
                        this.$vuetify.theme.dark = false
                        document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#1565C0');
                        app_data.tema = 'light'
                        localStorage.setItem("app_data", JSON.stringify(app_data))
                        break;
                    case 'dark':
                        this.sistema.tema = 'dark'
                        this.$vuetify.theme.dark = true
                        document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#121212');
                        app_data.tema = 'dark'
                        localStorage.setItem("app_data", JSON.stringify(app_data))
                        break;
                    case 'system':
                        this.sistema.tema = 'system'
                        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
                            this.$vuetify.theme.dark = true
                            document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#121212');
                            app_data.tema = 'system'
                            localStorage.setItem("app_data", JSON.stringify(app_data))
                        } 
                        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches){
                            this.$vuetify.theme.dark = false
                            document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#1565C0');
                            app_data.tema = 'system'
                            localStorage.setItem("app_data", JSON.stringify(app_data))
                        }
                        break;
                }
            }
        }
    }
}
</script>

<style scoped>
.tema-opciones >>> .v-list-item{
    padding: 4px !important;
    padding-right: 12px !important;
    border-radius: 8px !important;
    height: 48px;
}
</style>
